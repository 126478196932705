<template>
  <CCard>
    <CCardHeader class="vertical-center d-inline-flex flex-gap-2">
      <span v-if="user?.detail?.is_dispatcher" class="mt-1 dot bg-secondary">👔</span>
      <span v-else-if="user?.detail?.parent_id != null" class="mt-1 dot bg-secondary">👷</span>
      <CIcon v-else name="cil-user" />

      <h5 class="mb-0">
        {{ this.$route.meta.label }}
        <template v-if="user && !spinners.loading">
          : <h3 class="d-inline"><CBadge color="secondary">{{ user.first_name }}</CBadge></h3>
        </template>
      </h5>

      <div class="ml-auto">
        <CButton color="dark" :to="{ name: 'Couriers' }">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="courier-form" v-if="!(spinners.loading)">
        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <CTab active>
            <template slot="title">
              <CIcon name="cil-task"/> General Information
            </template>

            <div class="d-flex flex-column">
              <div v-if="!itemId" class="mb-3 d-flex flex-column flex-md-row">
                <v-select
                  class="w-100"
                  :placeholder="users.length == 0 ? 'Search for a user..' : 'Select or search..'"
                  v-model="user"
                  :options="users"
                  :searchable="true"
                  :loading="spinners.searching && users.length == 0"
                  @search="searchUsers"
                  @input="handleUserInput"
                >
                  <template v-slot:option="user">
                    <div class="d-flex align-items-center">
                      <div class="c-avatar mr-2">
                        <img :src="user.image_icon || user.gravatar" class="c-avatar-img">
                      </div>
                      <span class="small"><strong>{{ user.first_name }}</strong> #{{ user.id }}  ({{ user.email }})</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="user">
                    {{ user.first_name }} #{{ user.id }}
                  </template>
                  <span slot="no-options">Type to search users..</span>
                </v-select>
              </div>

              <template v-if="user">
                <!-- User Info -->
                <div class="d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4 h-100">
                  <div class="flex-fill align-self-center">
                    <CImg
                      :src="user.image_icon || user.gravatar"
                      class="rounded-circle bg-white"
                      style="width: 96px; height: 96px"
                      placeholderColor="lightgray"
                    />
                    <h2 class="d-inline ml-2">{{ user.first_name }}</h2>
                  </div>

                  <div class="p-2 mr-4 user-info">
                    <address class="mb-1 blockquote">
                      <strong>#{{ user.id }}</strong><br>
                      <CIcon name="cil-mobile" /> {{ form.detail.phone_number ?? user.mobile }}<br>
                      <CIcon name="cil-at" /> <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                    </address>
                    <address v-if="user?.dispatcher" class="mb-1 blockquote">
                      <span class="rounded-circle bg-secondary">👔</span><small> #{{ user.dispatcher.id }} {{ user.dispatcher.first_name }}</small>
                    </address>

                    <div class="button">
                      <CLink :to="{ name: 'Edit User', params: { id: user.id }, query: { tab: 0 } }">
                        <CIcon name="cil-pen" /> Edit User
                      </CLink>
                    </div>
                  </div>
                </div>

                <!-- Hubs -->
                <div class="form-group w-100 mb-2">
                  <label class="d-block">Hubs:</label>
                  <v-select
                    placeholder="Hubs.."
                    label="name"
                    v-model="form.hubs"
                    :options="hubs"
                    :searchable="false"
                    :reduce="hub => hub.id"
                    multiple
                  >
                    <template v-slot:option="hub">
                      <strong>{{ hub.zip }}</strong> -
                      <span>{{ hub.name }} </span>
                    </template>
                  </v-select>

                </div>

                <!-- Vehicle Info -->
                <div class="d-flex flex-column flex-md-row mb-2">
                  <CSelect
                    class="w-100 mr-2"
                    label="Travel Mode:"
                    placeholder="Please select.."
                    :options="getTravelModes"
                    :value.sync="$v.form.vehicle.mode.$model"
                  />

                  <CInput
                    class="w-100"
                    label="Vehicle Plate:"
                    placeholder="License Plate"
                    :value.sync="$v.form.vehicle.license_plate.$model"
                    @input="val => { if (val.trim() === '') form.vehicle.license_plate = null }"
                  />
                </div>

                <!-- Is Dispatcher? -->
                <div v-if="!user.detail?.parent_id" class="d-flex flex-column flex-md-row flex-gap-2 mb-4">
                  <div class="form-group w-100">
                    <label class="d-block" style="font-weight: 500">Is Dispatcher?</label>
                    <CSwitch
                      :checked.sync="$v.form.detail.is_dispatcher.$model"
                      :disabled="form.subordinates.length > 0"
                      @update:checked="value => setNullIfFalse(value, 'detail.is_dispatcher')"
                      labelOn="YES"
                      labelOff="NO"
                      color="success"
                      shape="pill"
                      variant="opposite"
                      size="lg"
                    />
                    <small v-if="form.detail.is_dispatcher" class="form-text text-muted">* This dispatcher is managing {{ form.subordinates.length }} couriers.</small>
                  </div>
                </div>

                <!-- Personal Info -->
                <h5 class="pb-1" style="border-bottom: 2px solid #636f83;">Personal Information</h5>

                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CSelect
                    class="w-100"
                    label="Country:"
                    placeholder="Please select.."
                    :options="authCountries"
                    :value.sync="$v.form.detail.country_id.$model"
                  />
                  <CInput
                    class="w-100"
                    label="National Registration Number:"
                    placeholder="Rijksregisternummer"
                    :value.sync="$v.form.detail.reg_number.$model"
                    @input="value => setNullIfEmpty(value, 'detail.reg_number')"
                  />
                </div>

                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CInput
                    type="date"
                    class="w-100"
                    label="Birth Date:"
                    :value.sync="$v.form.detail.birth_date.$model"
                    :max="new Date().toISOString().slice(0,10)"
                  />
                  <CInput
                    class="w-100"
                    label="Phone Number:"
                    placeholder="Phone number"
                    :lazy="false"
                    :value.sync="$v.form.detail.phone_number.$model"
                  />
                </div>

                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CInput
                    class="w-100"
                    label="Bank Account Number (IBAN):"
                    placeholder="Bank Account Number (IBAN)"
                    :lazy="false"
                    :value.sync="$v.form.detail.iban.$model"
                    :isValid="checkIfValid('detail.iban')"
                  />
                  <CInput
                    class="w-100"
                    label="Bank Account Holder:"
                    placeholder="Account Holder Name"
                    :value.sync="$v.form.detail.account_holder_name.$model"
                  />
                </div>

                <section class="address">
                  <CInput
                    id="verify_address"
                    :value.sync="verified_address"
                    class="w-100"
                    label="Verify Address From Google:"
                    placeholder="Enter a location"
                    :lazy="false"
                  >
                    <template #prepend>
                      <CButton color="info"><CIcon name="cil-location-pin"/></CButton>
                    </template>
                  </CInput>

                  <div class="d-flex flex-column flex-md-row">
                    <CTextarea
                      class="w-100"
                      rows="2"
                      label="Address:"
                      placeholder="Street name and house number"
                      :lazy="false"
                      :value.sync="$v.form.detail.address.$model"
                    />
                  </div>

                  <div class="d-flex flex-column flex-md-row flex-gap-2">
                    <CInput
                      class="w-100"
                      label="Postal Code:"
                      placeholder="Postal code"
                      :value.sync="$v.form.detail.postal_code.$model"
                    />
                    <CInput
                      class="w-100"
                      label="City:"
                      placeholder="City"
                      :value.sync="$v.form.detail.city.$model"
                    />
                  </div>
                </section>
              </template>
            </div>
          </CTab>
          
          <CTab v-if="isEdit">
            <template slot="title">
              <CIcon name="cil-image"/> ID card
            </template>

            <div class="d-flex flex-column col-xl-10 col-md-9">
              <template v-if="user">
                <h3>ID Card</h3>
                <cite>ID card images are used solely to validate the registered courier's identity.</cite>

                <div class="mt-3 articles">
                  <div>
                    <article class="mb-2">
                      <div class="article-wrapper">
                        <figure>
                          <input type="file" accept=".png, .jpg, .jpeg, .webp"  ref="identityFrontRef" style="display: none" @change="uploadFront" />
                          <expandable-image
                            :src="identityFront"
                            alt=""
                            v-if="identityFront"
                          />
                          <div v-else class="d-flex flex-column align justify-content-center align-items-center p-3" @click="$refs.identityFrontRef.click()">
                            <CIcon name="cil-plus" size="xl"/>
                            <cite class="mt-2 font-weight-light">No image is uploaded, please provide the front of ID card</cite>
                          </div>
                        </figure>
                        <div class="article-body">
                          <div class="d-flex flex-gap-1 mb-2 justify-content-between">
                            <CBadge color="info">Front</CBadge>
                            <div>
                              <button 
                                @click="$refs.identityFrontRef.click()" 
                                v-if="identityFront" 
                                type="button"
                                class="btn btn-primary mr-2"
                              >
                                  <CIcon name="cil-pencil"/>
                              </button>
                              <button 
                                @click="removeIDCard('FRONT')"
                                v-if="identityFront && isSuperAdmin && canDeleteIdentityFront" 
                                type="button"
                                class="btn btn-danger"
                              >
                                  <CIcon name="cil-trash"/>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <span v-if="identityFrontError" class="text-danger">{{ errors.identityFront }}</span>
                  </div>
                  <div>
                    <article class="mb-2">
                      <div class="article-wrapper">
                        <figure>
                          <input type="file" accept=".png, .jpg, .jpeg, .webp"  ref="identityBackRef" style="display: none" @change="uploadBack" />
                          <expandable-image
                            :src="identityBack"
                            alt=""
                            v-if="identityBack"
                          />
                          <div v-else class="d-flex flex-column align justify-content-center align-items-center p-3" @click="$refs.identityBackRef.click()">
                            <CIcon name="cil-plus" size="xl"/>
                            <cite class="mt-2 font-weight-light">No image is uploaded, please provide the back of ID card</cite>
                          </div>
                        </figure>
                        <div class="article-body">
                          <div class="d-flex flex-gap-1 mb-2 justify-content-between">
                            <CBadge color="info">Back</CBadge>
                            <div>
                              <button 
                                @click="$refs.identityBackRef.click()" 
                                v-if="identityBack" 
                                type="button"
                                class="btn btn-primary mr-2"
                              >
                                  <CIcon name="cil-pencil"/>
                              </button>
                              <button 
                                @click="removeIDCard('BACK')"
                                v-if="identityBack && isSuperAdmin && canDeleteIdentityBack" 
                                type="button"
                                class="btn btn-danger"
                              >
                                  <CIcon name="cil-trash"/>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                    <span v-if="identityBackError" class="text-danger">{{ errors.identityBack }}</span>
                  </div>
                </div>
              </template>
            </div>
          </CTab>

          <CTab :disabled="!form.user_id">
            <template slot="title">
              <div><CIcon name="cil-pen-nib"/> Contract</div>
            </template>
            <h3>Contract</h3>

            <div class="d-flex flex-column">
              <!-- Business Info -->
              <h5 class="pb-1 mt-1 mb-3" style="border-bottom: 2px solid #636f83;">Business Information</h5>

              <div v-if="hasActiveContract" class="alert alert-info p-2">
                <div class="vertical-center flex-gap-2">
                  <div class="h1 mb-0">🛈</div>
                  <div class="flex-grow-1 lead">Contract start date: {{ form.active_contract.start_date }}</div>
                  <CInputCheckbox custom label="Edit" :checked.sync="editContract" />
                </div>
              </div>

              <div :class="{ 'disable-item': hasActiveContract && !editContract }">
                <!-- contract_model, employment_type -->
                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CSelect
                    class="w-100"
                    label="Contract Model:"
                    placeholder="Please select.."
                    :options="[
                      { value: 'per_order', label: 'Per order' },
                      { value: 'hourly', label: 'Hourly' }
                    ]"
                    :value.sync="$v.form.active_contract.contract_model.$model"
                    @change="() => form.active_contract.employment_type = null"
                  />

                  <CSelect
                    class="w-100"
                    label="Employment Type:"
                    placeholder="Please select.."
                    :options="employmentTypeOptions"
                    :value.sync="$v.form.active_contract.employment_type.$model"
                  />
                </div>

                <!-- vat_number, company_name -->
                <div v-if="vatRequired" class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CInput
                    class="w-100"
                    label="VAT (BTW) Number:"
                    placeholder="VAT number, if any"
                    :value.sync="$v.form.active_contract.vat_number.$model"
                    @focusout="fixVat"
                    :isValid="checkIfValid('active_contract.vat_number')"
                    @input="value => setNullIfEmpty(value, 'active_contract.vat_number')"
                  >
                    <template #append>
                      <CButton
                        color="info"
                        :disabled="spinners.btnCompanyInfo || !form.active_contract.vat_number || !isValidVat(form.active_contract.vat_number)"
                        @click="getCompanyInfo"
                      >
                        <CSpinner size="sm" v-if="spinners.btnCompanyInfo" />
                        Get Company Info
                      </CButton>
                    </template>
                  </CInput>

                  <CInput
                    class="w-100"
                    label="Company Name:"
                    placeholder="Company name"
                    :value.sync="$v.form.active_contract.company_name.$model"
                  />
                </div>

                <!-- rate -->
                <div class="d-flex flex-column flex-md-row flex-gap-2 mb-2">
                  <CInput
                    class="w-100"
                    :label="`Rate for '${form.active_contract?.contract_model?.replace('_', ' ')}' contract model:`"
                    placeholder="0.00"
                    :value.sync="$v.form.active_contract.rate.$model"
                    type="number"
                    step="0.01"
                    pattern="^\d+(?:\.\d{1,2})?$"
                  >
                    <template #prepend-content><CIcon name="cil-euro"/></template>
                  </CInput>

                  <div class="w-100"></div>
                </div>
              </div>

              <!-- Contract History -->
              <h5 class="pb-1 my-3" style="border-bottom: 2px solid #636f83;">Contract History</h5>
              <ContractHistory :courierId="form.user_id?.toString()" />
            </div>
          </CTab>

          <CTab :disabled="!form.user_id">
            <template slot="title">
              <div><CIcon name="cil-lock-unlocked"/> Permissions</div>
            </template>
            <h3>Permissions</h3>
            <courier-permissions :permissions.sync="form.detail.permissions" />
          </CTab>

          <CTab :disabled="!form.user_id || hasFlexibleWorkingHours">
            <template slot="title">
              <div><CIcon name="cil-clock"/> Working Hours</div>
            </template>
            <h3>Working Hours</h3>
            <working-hours
              :workingHours.sync="workingHours"
              @validate="(invalid) => workingHoursInvalid = invalid"
            />
          </CTab>

          <CTab :disabled="!form.user_id || hasFlexibleWorkingHours">
            <template slot="title">
              <div><CIcon name="cil-mood-good"/> Custom Working Hours</div>
            </template>
            <h3>Custom Working Hours</h3>
            <custom-working-hours
              :customWorkingHours.sync="customWorkingHours"
              @validate="(invalid) => customWorkingHoursInvalid = invalid"
            />
          </CTab>

          <CTab v-if="form.detail.is_dispatcher">
            <template slot="title">
              <div><span class="dot bg-secondary">👷</span> Subordinates</div>
            </template>
            <h3>Subordinate Couriers</h3>

            <CLink @click="addSubordinatesModalShow = true" class="mb-3 d-block">
              <CIcon name="cil-plus" /> Add couriers as subordinates
            </CLink>

            <CourierList :couriers.sync="form.subordinates" />
          </CTab>

          <CTab>
            <template slot="title">
              <CIcon name="cil-notes"/> Registration Notes
            </template>

            <div class="d-flex flex-column col-xl-10 col-md-9">
              <template v-if="user">
                <h3>Registration Notes</h3>
                <cite v-if="validRegNotes">A courier registration has been received via our website. Please review and process the provided information to activate the courier's account.</cite>
                <cite v-else>There are no registration notes available for this courier.</cite>

                <div class="mt-3">
                  <ul v-if="validRegNotes">
                    <li v-if="user.detail?.registration_notes.selectedCity != null">Wants to work in: <strong>{{ user.detail?.registration_notes.selectedCity?.capitalize() }}</strong></li>
                    <li v-if="user.detail?.registration_notes.isFlexHours != null">Contract model:  <strong>{{ user.detail?.registration_notes.isFlexHours ? 'Per Order' : 'Hourly' }}</strong></li>
                    <li v-if="this.user.detail?.registration_notes.selectedShift != null">Working <strong>{{ shift }}</strong></li>
                    <li v-if="user.detail?.registration_notes.selectedVehicle != null">Want's to use <strong>{{ getTravelModeByType(user.detail?.registration_notes.selectedVehicle).label.toLowerCase() }}</strong> {{ user.detail?.registration_notes.licensePlate ? 'with license plate: ' : '' }} <strong v-if="user.detail?.registration_notes.licensePlate">{{ user.detail?.registration_notes.licensePlate }}</strong></li>
                  </ul>
                  <div v-else class="d-flex justify-content-center mt-5">
                    <CIcon name="cil-warning" class="warning-icon"/>
                  </div>
                </div>
              </template>
            </div>
          </CTab>
        </CTabs>
      </CForm>

      <CSpinner v-else color="primary" />
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="spinners.loading || spinners.btnSubmit || !form.user_id"
          @click="submit()"
        >
          <CSpinner v-if="spinners.btnSubmit" size="sm" /> Submit
        </CButton>
        <CButton
          color="success"
          :disabled="!showUploadBtn"
          @click="uploadIdentity()"
        >
          <CSpinner v-if="spinners.btnUpload" size="sm" /> Upload ID Card
        </CButton>
      </div>
    </CCardFooter>

    <CModal
      class="add-couriers-modal"
      title="Add Couriers"
      color="white"
      size="lg"
      :show.sync="addSubordinatesModalShow"
      :closeOnBackdrop="false"
    >
      <!-- :courier-ids="user?.subordinates.pluck('id').join()" -->
      <add-couriers
        v-if="user"
        :active="addSubordinatesModalShow"
        :courier-ids="[user?.id, ...form?.subordinates?.pluck('id')].join(',')"
        :selected-items.sync="newSubordinates"
        :options="{ excludeSubordinates: true, excludeDispatchers: true }"
      />

      <template #footer class="p-1">
        <CButton @click="addSubordinatesModalShow = false" color="link">Close</CButton>
        <CButton @click="addSubordinates" color="success" :disabled="!newSubordinates.length">
          <CIcon name="cil-plus" class="mt-0" /> Add
        </CButton>
      </template>
    </CModal>

    <mc-spinner :opacity="0.8" v-show="spinners.btnSubmit || spinners.btnUpload" />
  </CCard>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate"
import { helpers, required, requiredIf } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';
import CourierPermissions from "@/components/Permissions/Courier.vue";
import WorkingHours from './WorkingHours'
import CustomWorkingHours from './CustomWorkingHours'
import AddCouriers from "@/components/Courier/AddCouriers";
import CourierList from "@/components/Courier/CourierList";
import ContractHistory from "@/components/Courier/ContractHistory";
import ExpandableImage from './ExpandableImage'
import { Role } from "../../../router/role";

const validIBAN = (value) => !helpers.req(value) || IBAN.isValid(value)

const hasHouseNumber = (address) => {
  var regex = /\d+/g;
  return regex.test(address);
}

export default {
  name: 'Courier',
  components: {
    'courier-permissions': CourierPermissions,
    'working-hours': WorkingHours,
    'custom-working-hours': CustomWorkingHours,
    'add-couriers': AddCouriers,
    CourierList,
    ContractHistory,
    ExpandableImage
  },

  data() {
    return {
      activeTab: 0,
      isEdit: false,
      form: {
        user_id: null,
        hubs: [],
        vehicle: {
          mode: 'TWO_WHEELER',
          license_plate: null,
        },
        working_hours: [],
        detail: {
          reg_number: null,
          birth_date: null,
          phone_number: null,
          iban: null,
          account_holder_name: null,
          address: null,
          city: null,
          postal_code: null,
          country_id: null,

          parent_id: null,
          is_dispatcher: null,

          permissions: []
        },
        active_contract:{
          contract_model: 'hourly',
          employment_type: 'employee',
          rate: null,
          vat_number: null,
          company_name: null,
        }
      },

      workingHours: [],
      customWorkingHours: [],
      newSubordinates: [],
      workingHoursInvalid: false,
      customWorkingHoursInvalid: false,
      addSubordinatesModalShow: false,

      user: null,
      users: [],
      hubs: [],

      spinners: {
        loading: false,
        searching: false,
        btnSubmit: false,
        btnCompanyInfo: false,
        btnUpload: false,
      },
      unsavedChanges: false,
      editContract: false,

      // Google Maps API Autocomplete
      verified_address: '',
      autocomplete_address: null,

      // Vuelidate-error-extractor
      attributes: {
        user_id: 'User',
        detail: {
          reg_number: 'National registration number',
          birth_date: 'Birth date',
          phone_number: 'Phone number',
          iban: 'Bank account number (IBAN)',
          account_holder_name: 'Bank account holder name',
          address: 'Address',
          city: 'City',
          postal_code: 'Postal code',
          country_id: 'Country',
        },
        vehicle: {
          mode: 'Travel mode',
        },
        active_contract: {
          contract_model: '[Contract] Contract model',
          employment_type: '[Contract] Employment type',
          rate: '[Contract] Rate for contract model',
          vat_number: '[Contract] VAT (BTW) number',
          company_name: '[Contract] Company name',
        }
      },

      // ID Card
      identityFront: '',
      identityBack: '',
      identityFrontError: false,
      identityBackError: false,
      canDeleteIdentityFront: false,
      canDeleteIdentityBack: false,
      selectedIdentityFront: {
        file: null,
        name: '',
        src: '',
        base64: '',
        uploaded: false,
      },
      selectedIdentityBack: {
        file: null,
        name: '',
        src: '',
        base64: '',
        uploaded: false,
      },
      identityMap: {
        FRONT: 'identityFront',
        BACK: 'identityBack'
      },
      showUploadBtn: false,
    }
  },

  watch: {
    'selectedIdentityFront.file': function(newVal, oldVal) {
      if (this.selectedIdentityFront.uploaded) {
        this.canDeleteIdentityFront = true;
        this.showUploadBtn = false;
      } else {
        this.canDeleteIdentityFront = false;
        this.showUploadBtn = true;
      }
    },
    'selectedIdentityBack.file': function(newVal, oldVal) {
      if (this.selectedIdentityFront.uploaded) {
        this.canDeleteIdentityBack = true;
        this.showUploadBtn = false;
      } else {
        this.canDeleteIdentityBack = false;
        this.showUploadBtn = true;
      }
    },
  },

  mixins: [validationMixin],
  validations: {
    form: {
      user_id: { required },
      hubs: { },
      vehicle: {
        mode: { required },
        license_plate: { },
      },
      detail: {
        reg_number: { required },
        birth_date: { required },
        phone_number: { required },
        iban: { validIBAN },
        account_holder_name: {
          required: requiredIf(function (value) {
            return !!(value.iban);
          }),
        },
        address: { required, hasHouseNumber },
        city: { required },
        postal_code: { required },
        country_id: { required },

        parent_id: { },
        is_dispatcher: { },
      },
      active_contract: {
        contract_model: { required },
        employment_type: { required },
        rate: { required },

        vat_number: {
          required: requiredIf(function (value) {
            return this.vatRequired && !!value.vat_number;
          }),
          checkVAT: function (value) {
            return this.isValidVat(value);
          },
        },

        company_name: {
          required: requiredIf(function () {
            return this.vatRequired;
          }),
        }
      }
    }
  },

  // Route koruması için
  beforeRouteLeave (to, from , next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  },

  async beforeMount() {
    this.searchUsers = _debounce(async (val) => {
      await this.getUsers(val);
    }, 500);

    await this.getHubs();

  },

  async mounted() {
    this.spinners.loading = true;
    await this.fetchTravelModes();
    this.spinners.loading = false;

    this.activeTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0;

    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', { handler: 'watchForm', deep: true });
    this.$watch('workingHours', { handler: 'watchForm', deep: true });
    this.$watch('customWorkingHours', { handler: 'watchForm', deep: true });

    if (this.itemId) {
      await this.getForm(this.itemId);
      await this.getIDCard();
      this.initAutocomplete();
    } else {
      this.form.detail.permissions = this.$globalVar.courierPermissions.filter(p => p.default).pluck('name');
    }

    this.$watch('form.detail.country_id', function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.autocomplete_address.setComponentRestrictions({
          country: [this.country?.iso2]
        });
      }
    });

    setTimeout(() => {
      sessionStorage.setItem(`courier-form`, JSON.stringify(this.transformedForm));
      this.unsavedChanges = false;
    }, 500);
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters('settings', ['getTravelModes', 'getTravelModeByType']),

    isValid() { return !(this.$v.form.$invalid)},
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return Number(this.$route.params.id) || null },
    transformedForm() {
      const { hubs, working_hours, detail, ...rest } = this.form;

      const permissionsMap = this.$globalVar.courierPermissions.reduce((acc, { name }) => {
        acc[name] = detail.permissions.includes(name);
        return acc;
      }, {});

      return {
        ...rest,
        hubs: this.form.hubs.join(','),
        working_hours: [...this.workingHours, ...this.customWorkingHours],
        detail: {
          ...detail,
          ...permissionsMap,
          permissions: undefined // Remove the permission property
        },
        subordinates: this.form.subordinates?.pluck('id').join(','),
      }
    },
    country() {
      return this.authCountries.find((item) => item.value == (this.form.detail.country_id || this.$store.state.countryId));
    },
    hasActiveContract() { return !!(this.form.active_contract.start_date) },
    hasFlexibleWorkingHours() { return this.form.active_contract?.contract_model === 'per_order' },
    vatRequired() {
      // Check if active_contract exists
      if (this.form.active_contract) {
          return this.form.active_contract.employment_type === 'self_employed';
      }
      return false;
    },
    employmentTypeOptions() {
      const model = this.form.active_contract?.contract_model;
      const baseOptions = [{ value: 'self_employed', label: 'Self Employed' }];

      if (model === 'per_order') {
        return [{ value: 'p2p', label: 'P2P' }, ...baseOptions];
      } else if (model === 'hourly') {
        return [{ value: 'employee', label: 'Employee' }, ...baseOptions];
      }

      return [];
    },
    shift() {
      if(this.user.detail?.registration_notes?.selectedShift != null) {
        switch (this.user.detail?.registration_notes.selectedShift) {
          case 'weekend':
            return 'only during the weekend';
          case 'weekday':
            return 'only during the week';
          case 'flex':
            return 'both during the week and weekend';
        }
      }
    },
    validRegNotes() {
      // Get the registration notes (could be null/undefined)
      const regNotes = this.user.detail?.registration_notes;
      
      // If it's null/undefined, return false
      if (!regNotes) return false;
      
      // Get the keys from the registration notes object
      const keys = Object.keys(regNotes);
      
      // If there are no keys, it's considered empty, so return false
      if (keys.length === 0) return false;
      
      // If all keys are either "identityFront" or "identityBack", return false
      if (keys.every(key => ['identityFront', 'identityBack'].includes(key))) {
        return false;
      }
      
      // Otherwise, there's at least one key that is not "identityFront"/"identityBack"
      return true;
    },
    isSuperAdmin() { return this.authUserRoles.includes(Role.SuperAdmin) }
  },

  methods: {
    openIDCard() {
      this.$store.commit('set', ['idCardShow', true]);
    },
    ...mapActions('settings', ['fetchTravelModes']),
    
    checkIfValid (fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    validate() {
      this.$v.$touch()
    },

    watchForm(newV, oldV) {
      const initialState = sessionStorage.getItem(`courier-form`);
      const newState = JSON.stringify(this.transformedForm);
      this.unsavedChanges = initialState !== newState;
    },

    async getUsers(val) {
      if (val.length < 3) {
        return;
      }

      this.spinners.searching = true;
      await this.$axios.get(this.$backend.USERS.GET_ALL, {
        params: {
          search: val,
          'get-relations': false,
          'except-couriers': true,
        }
      })
        .then(({ data }) => {
          this.users = data.data.map((el) => {
            return { ...el, label: `i#${el.id} ${el.first_name} ${el.email}` };
          });
          this.spinners.searching = false;
        });
    },
    
    async getIDCard() {
      const registrationNotes = this.user.detail.registration_notes;
      
      // Return early if both identityFront and identityBack are missing
      if (!registrationNotes?.identityFront && !registrationNotes?.identityBack) {
        return;
      }

      this.spinners.loading = true;
      const names = [];

      if (registrationNotes?.identityFront) {
        names.push(`${registrationNotes.identityFront}.webp`);
      }
      
      if (registrationNotes?.identityBack) {
        names.push(`${registrationNotes.identityBack}.webp`);
      }

      try {
        const { data } = await this.$axios.post(this.$backend.COURIER.COURIERS.ID_CARD, {
          type: "couriers/ids",
          names: names
        });

        if (data.length !== 0) {
          let dataIndex = 0;
          if (registrationNotes?.identityFront) {
            this.identityFront = data[dataIndex].image;
            this.canDeleteIdentityFront = true;
            dataIndex++;
          }
          
          if (registrationNotes?.identityBack) {
            // Use the next element from data. It will be data[0] if only identityBack exists.
            this.identityBack = data[dataIndex]?.image;
            this.canDeleteIdentityBack = true;
          }
        }
      } catch (error) {
        this.$toast.error(`${error}`);
      } finally {
        this.spinners.loading = false;
      }
    },
    
    async removeIDCard(type) {
      // Determine which field to use.
      const field = this.identityMap[type];
      if (!field) return;
      
      // Get the identity value from the user data.
      const identityValue = this.user.detail.registration_notes?.[field];
      if (!identityValue) return;

      // Build the image name.
      const name = `${identityValue}.webp`;

      // Start the spinner.
      this.spinners.btnSubmit = true;

      try {
        // Make the DELETE request.
        const { data } = await this.$axios.delete(this.$backend.COURIER.COURIERS.ID_CARD, {
          data: {
            type: "couriers/ids",
            name,
            cid: this.user.id
          }
        });

        if (data.success) {
          this.removeIDCardImage(type);
        }
      } catch (error) {
        console.error(error);
        this.$toast.error(`${error}`);
      } finally {
        // Stop the spinner regardless of success or error.
        this.spinners.btnSubmit = false;
      }
    },

    removeIDCardImage(type) {
      const field = this.identityMap[type];
      if (!field) return;

      // Check if the field exists.
      if (!this.user.detail.registration_notes?.[field]) return;

      // Clear the corresponding component property.
      // For 'FRONT', this sets this.identityFront = ''; for 'BACK', this sets this.identityBack = '';
      this[`identity${type === 'FRONT' ? 'Front' : 'Back'}`] = '';

      // Remove the property from registration_notes.
      delete this.user.detail.registration_notes[field];
    },

    handleUserInput(user) {
      this.form.detail.is_dispatcher = null;
      this.form.subordinates = [];

      if (user === null) {
        this.form.user_id = null;
        this.form.detail.phone_number = null;
        this.form.detail.address = null;
        this.form.detail.city = null;
        this.form.detail.postal_code = null;
        this.form.detail.country_id = null;
        return;
      }

      this.form.user_id = user.id;
      this.form.detail.phone_number = user.mobile;
      this.form.detail.address = user.address;
      this.form.detail.city = user.city;
      this.form.detail.postal_code = user.postal_code;

      this.$nextTick(() => {
        this.initAutocomplete();
        this.form.detail.country_id = this.country.value;
      });
    },

    async getForm() {
      this.isEdit = true
      this.spinners.loading = true;

      await this.$axios.get(this.$backend.COURIER.COURIERS.SHOW.replace("{id}", this.itemId))
        .then(({data}) => {
          this.user = data;

          this.form = {
            user_id: data.id,
            hubs: data.hubs?.pluck('id'),
            vehicle: {
              mode: data.vehicle?.mode,
              license_plate: data.vehicle?.license_plate,
            },
            working_hours: data.future_working_hours,
            detail: {
              reg_number: data.detail.reg_number ?? '-',
              birth_date: data.detail.birth_date ?? '2000-01-01',
              phone_number: data.detail.phone_number ?? this.user.mobile,
              iban: data.detail.iban,
              account_holder_name: data.detail.account_holder_name,
              address: data.detail.address ?? this.user.address,
              city: data.detail.city ?? this.user.city,
              postal_code: data.detail.postal_code ?? this.user.postal_code,
              country_id: data.detail.country_id ?? this.country.value,

              parent_id: data.detail.parent_id,
              is_dispatcher: data.detail.is_dispatcher,

              permissions: this.$globalVar.courierPermissions.filter(p => data.detail[p.name]).pluck('name')
            },
            active_contract: {
              contract_model: data.active_contract?.contract_model ?? 'hourly',
              employment_type: data.active_contract?.employment_type ?? 'employee',
              rate: data.active_contract?.rate ?? 0,
              vat_number: data.active_contract?.vat_number,
              company_name: data.active_contract?.company_name ?? '-',
              start_date: data.active_contract?.start_date,
            },
            subordinates: data.subordinates
          }

          this.workingHours = data.future_working_hours.filter((wh) => wh.day_of_week != null && !wh.custom_date);
          this.customWorkingHours = data.future_working_hours
            .filter((wh) => wh.day_of_week == null && wh.custom_date)
            .map(el => ({ ...el, invalid: false }));

          this.spinners.loading = false;
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
          setTimeout(() => { this.$router.push({ name: 'Couriers' }) }, 3000);
        })
        .finally(() => { this.unsavedChanges = false })
    },

    async getHubs() {
      await this.$axios
        .get(this.$backend.COURIER.HUBS.GET_LIST)
        .then(({ data }) => this.hubs = data)
        .catch(() => this.hubs = [])
    },

    async submit() {
      window.scrollTo(0, 0);

      if (!this.isValid) {
        this.validate();
        this.activeTab = 0;
        return;
      }

      if (this.workingHoursInvalid) {
        this.activeTab = 3;
        this.$toast.error('Check the working hours!')
        return;
      }

      if (this.customWorkingHoursInvalid) {
        this.activeTab = 4;
        this.$toast.error('Check the custom working hours!')
        return;
      }

      if (this.isEdit) {
        await this.update();
        // await this.uploadIdentity();
      } else {
        await this.store()
      }
    },

    async update() {
      this.spinners.btnSubmit = true

      await this.$axios.put(this.$backend.COURIER.COURIERS.UPDATE.replace("{id}", this.itemId), this.transformedForm)
        .then(({ data }) => {
          this.$toast.success(`${data.data.message}`);
          this.customWorkingHours = this.customWorkingHours.filter((wh) => !wh.deleted);
          this.form.active_contract = this.$globalFunc.extractKeys(data.data.courier.active_contract, ['contract_model', 'employment_type', 'rate', 'vat_number', 'company_name', 'start_date']);

          sessionStorage.setItem(`courier-form`, JSON.stringify(this.transformedForm));
          this.unsavedChanges = false;
          this.editContract = false;
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    async store() {
      this.spinners.btnSubmit = true

      await this.$axios.post(this.$backend.COURIER.COURIERS.STORE, this.transformedForm)
        .then(({ data }) => {
          this.$toast.success(`${data.data.message}`);
          this.unsavedChanges = false

          setTimeout(() => {
            this.$router.push({ name: 'Couriers' })
          }, 2000)
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    addSubordinates() {
      this.form.subordinates = this.form.subordinates.concat(
        this.newSubordinates.filter(newCourier => !this.form.subordinates.some(c => c.id === newCourier.id)));
      this.newSubordinates = [];
      this.addSubordinatesModalShow = false;
    },

    setNullIfEmpty(value, path) {
      if (value.trim() === '') {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const obj = keys.reduce((acc, key) => acc[key], this.form);
        obj[lastKey] = null;
      }
    },
    setNullIfFalse(value, path) {
      if (value === false) {
        const keys = path.split('.');
        const lastKey = keys.pop();
        const obj = keys.reduce((acc, key) => acc[key], this.form);
        obj[lastKey] = null;
      }
    },

    // VAT Number
    fixVat(event) {
      const fixedValue = this.$globalFunc.fixVAT(event.target.value);
      event.target.value = fixedValue;
    },
    isValidVat(vatNumber) {
      const result = this.$globalFunc.checkVAT(vatNumber, this.country.value);
      return result || !helpers.req(vatNumber) || false;
    },
    async getCompanyInfo() {
      this.spinners.btnCompanyInfo = true

      try {
        const { data } = await this.$axios.post(this.$backend.RESTAURANT.VAT_VALIDATION, {
          country_code: this.country?.iso2.toUpperCase(),
          vat_number: this.form.active_contract.vat_number,
        });

        if (data.response) {
          const name = data.response.name.replace(/\u00a0/g, ' ');
          const address = data.response.address.replace(/\u00a0/g, ' ').trim();

          const [firmType, ...firmName] = name.includes('|')
            ? name.split('|').map(part => part.trim())
            : name.split(' ');

          // this.form.active_contract.company_type = firmType;
          this.form.active_contract.company_name = firmName.join(" ");

          // address: "Zeven Septemberlaan 7\n3550 Heusden-Zolder"
          const [firmAddress, postcodeAndCity] = address.split('\n');
          const [firmPostcode, ...firmCity] = postcodeAndCity.split(' ');

          this.form.detail.address = firmAddress;
          this.form.detail.postal_code = firmPostcode;
          this.form.detail.city = firmCity.join(" ");

          this.$toast.success(`Valid VAT number`);
        } else {
          this.$toast.error(`Invalid VAT (BTW) number!`);
        }
      } catch (error) {
        this.$toast.error(error.response.data.message);
      } finally {
        this.spinners.btnCompanyInfo = false;
      }
    },

    // Google places
    initAutocomplete() {
      // Google Maps API Autocomplete: Place Autocomplete Address Form
      this.autocomplete_address = new google.maps.places.Autocomplete(
        document.getElementById("verify_address"),
        {
          componentRestrictions: { country: [this.country?.iso2] },
          fields: ["address_components", "geometry", "formatted_address"],
          types: ["address"],
        }
      );
      this.autocomplete_address.addListener("place_changed", this.setPlace);
    },
    setPlace() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete_address.getPlace();

      let street_number = "";
      let route = "";
      let postcode = "";
      let locality = "";

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            street_number = component.long_name;
            break;
          }

          case "route": {
            route = component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            locality = component.long_name;
            break;
          }
        }
      }

      this.verified_address = place.formatted_address;

      this.form.detail.address = `${route} ${street_number}`;
      this.form.detail.postal_code = postcode.replace(/\D/g, "");
      this.form.detail.city = locality;
    },

    // Upload ID Card
    async uploadFront(event) {
      const files = event.target.files;
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024; 
      this.errors.identityFront = '';
      this.identityFrontError = false;

      if(files.length > 0) {
        const file = files[0];
        const validFileTypes = ['image/png', 'image/jpeg','image/webp']; 
        if (!validFileTypes.includes(file.type)) {
          this.identityFrontError = true;
          this.errors.identityFront = "The front of the ID is invalid. Ensure that the file type is correct (only PNG, JPG, or JPEG allowed).";
          return;
        } else {
          this.identityFrontError = false;
          this.errors.identityFront = ""
        }

        if (file.size > maxSizeInBytes) {
          this.identityFrontError = true;
          this.errors.identityFront = "The file size for the front of the ID is too large. Maximum 5MB allowed.";
          return;
        } else {
          this.identityFrontError = false;
          this.errors.identityFront = ""
        }

        this.selectedIdentityFront.base64 = await this.fileToBase64(file);
        this.identityFront = this.selectedIdentityFront.base64;
        this.selectedIdentityFront.file = file;
        this.selectedIdentityFront.name = file.name;

        if(this.selectedIdentityFront.file != null) {
          this.selectedIdentityFront.src = URL.createObjectURL(this.selectedIdentityFront.file);
          this.selectedIdentityFront.uploaded = false;
        }
      }
    },

    async uploadBack(event) {
      const files = event.target.files;
      const maxSizeInMB = 5; 
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024; 
      this.errors.identityBack = '';
      this.identityBackError = false;

      if(files.length > 0) {
        const file = files[0];
        const validFileTypes = ['image/png', 'image/jpeg','image/webp']; 
        if (!validFileTypes.includes(file.type)) {
          this.identityBackError = true;
          this.errors.identityBack = "The back of the ID is invalid. Ensure that the file type is correct (only PNG, JPG, or JPEG allowed).";
          return;
        } else {
          this.identityBackError = false;
          this.errors.identityBack = "";
        }

        if (file.size > maxSizeInBytes) {
          this.identityBackError = true;
          this.errors.identityBack = "The file size for the back of the ID is too large. Maximum 5MB allowed.";
          return;
        } else {
          this.identityBackError = false;
          this.errors.identityBack = "";
        }

        this.selectedIdentityBack.base64 = await this.fileToBase64(file);
        this.identityBack = this.selectedIdentityBack.base64;
        this.selectedIdentityBack.file = file;
        this.selectedIdentityBack.name = file.name;

        if(this.selectedIdentityBack.file != null) {
          this.selectedIdentityBack.src = URL.createObjectURL(this.selectedIdentityBack.file);
          this.selectedIdentityBack.uploaded = false;
        }
      }
    },

    async uploadIdentity() {
      this.spinners.btnUpload = true;

      // Prepare an array of upload promises.
      const uploads = [];
      if (this.selectedIdentityFront.file !== null) {
        uploads.push(this.uploadToCDN());
      }
      if (this.selectedIdentityBack.file !== null) {
        uploads.push(this.uploadToCDN(true));
      }

      // Wait for all uploads to finish.
      await Promise.all(uploads);

      this.spinners.btnUpload = false;
    },

    async uploadToCDN(back = false) {
      // Determine which identity we're dealing with.
      const identity = back ? this.selectedIdentityBack : this.selectedIdentityFront;
      const identityKey = back ? 'identityBack' : 'identityFront';
      const nameSuffix = back ? 'Back' : 'Front';

      // Build the request body.
      const body = {
        name: `${this.user.first_name} ${nameSuffix}`,
        base64: identity.base64,
        fileName: identity.name,
        removeOld: false,
        oldFileName: ''
      };

      // If an image already exists, mark for removal.
      if (this.user.detail?.registration_notes?.[identityKey]) {
        body.removeOld = true;
        body.oldFileName = this.user.detail.registration_notes[identityKey];
      }

      // Convert the base64 image to a Blob.
      const blob = this.base64ToBlob(body.base64);

      // Build the FormData for the request.
      const formData = new FormData();
      formData.append('image', blob, body.fileName);
      formData.set('type', 'couriers/ids');
      formData.set('removeOld', body.removeOld);
      formData.set('oldFileName', body.oldFileName);
      formData.set('name', body.name);
      formData.set('cid', this.user.id);

      try {
        const { data } = await this.$axios.post(this.$backend.COURIER.COURIERS.UPLOAD, formData);
        if (data.status === 'success') {
          // Update the identity key with the new image.
          this.$set(this.user.detail.registration_notes, identityKey, data.data.image);

          // Update selectedIdentity'Front'or'Back' variable uploaded to true
          this[`selectedIdentity${back ? 'Back' : 'Front'}`].uploaded = true;
          this[`selectedIdentity${back ? 'Back' : 'Front'}`].file = null;
          this[`selectedIdentity${back ? 'Back' : 'Front'}`].base64 = '';
          this[`selectedIdentity${back ? 'Back' : 'Front'}`].name = '';
          this[`selectedIdentity${back ? 'Back' : 'Front'}`].src = '';
        }
      } catch (error) {
        console.error('Error uploading identity:', error);
        this[`selectedIdentity${back ? 'Back' : 'Front'}`].uploaded = false;
      }
    },

    base64ToBlob(base64) {
      const [header, data] = base64.split(',');
      const mimeString = header.match(/:(.*?);/)[1];
      const byteString = atob(data);
      const ia = Uint8Array.from(byteString, char => char.charCodeAt(0));
      return new Blob([ia], { type: mimeString });
    },

    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
  }
}
</script>

<style>
.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  /* border-bottom: 1px solid #d8dbe0; */
}

form.courier-form .form-control,
form.courier-form option,
form.courier-form .vs__search,
form.courier-form .vs__dropdown-menu,
form.courier-form .vs__selected {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
form.courier-form .vs__dropdown-toggle {
  padding: 2px 0 6px;
}
form.courier-form label.c-switch {
  margin-top: 0.3rem;
}

.add-couriers-modal .modal-header {
  padding: 0.75rem !important;
}
.add-couriers-modal .modal-footer {
  padding: 0.25rem !important;
}
</style>

<style scoped>
.warning-icon {
  color: rgba(139, 139, 139, 0.134);
  width: 5rem !important;
  height: 5rem !important;
  font-size: 5rem !important;
}

.user-info {
  position: relative;
}
.user-info .button {
  opacity: 0;
  transition: opacity .35s ease;
  background: rgba(0, 0, 0, .3);
  text-align: center;
  position: absolute;
  width: 100%;
  border-radius: 25px;
}
.user-info .button a {
  text-align: center;
  color: white;
  z-index: 1;
}
.user-info:hover .button {
  opacity: 1;
}

section.address {
  background: #F8F0DF;
  padding: 1rem;
  border-radius: 0.5rem;
}

.articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 480px) {
  .articles {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

article {
  --link-icon-translate: -3px;
  --link-icon-opacity: 0.5;

  position: relative;
  border-radius: 12px;
  box-shadow: none;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  outline: 1px solid #ebebeb;
}

.article-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 40 / 9;
  overflow: hidden;
  cursor: pointer;
}

article img {
  max-width: 100%;
}

.article-body {
  padding: 12px;
}

.article-body .badge-info {
  height: fit-content;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

article:has(:hover, :focus) {
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 10px 20px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
</style>
