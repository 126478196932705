<template>
    <div
        class="expandable-image"
    >
        <!-- Expand button (only when not expanded) -->
      <i class="expand-button">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="#000000" d="M10,21V19H6.41L10.91,14.5L9.5,13.09L5,17.59V14H3V21H10M14.5,10.91L19,6.41V10H21V3H14V5H17.59L13.09,9.5L14.5,10.91Z"/>
        </svg>
      </i>

      <!-- Inline view: when not expanded, just show the image -->
      <img
        v-if="!expanded"
        v-bind="$attrs"
        @click="expanded = true"
        class="inline-image"
      />
  
      <!-- Expanded overlay: full-screen container with dark backdrop -->
      <div v-if="expanded" class="expanded-overlay" @click="closeExpanded" @mousemove="onMouseMove" @touchmove="onTouchMove">
        <!-- Zoom button in the top right corner -->
        <button class="zoom-button" @click.stop="toggleZoom">
            <CIcon v-if="isZoomed" name="cil-zoom-out"/>
            <CIcon v-else name="cil-zoom-in"/>
        </button>
        <!-- Close button (could be placed anywhere; here in the overlay too) -->
        <button class="close-button" @click="closeExpanded"><CIcon name="cil-x"/></button>
  
        <!-- Image container: holds the zoomable image -->
        <div class="image-container">
          <img
            ref="zoomableImage"
            v-bind="$attrs"
            :style="imageStyle"
            @click.stop="toggleZoom"
          />
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    inheritAttrs: false,
    data() {
      return {
        // Whether the image is expanded (full-screen overlay)
        expanded: false,
        // Whether the zoom is active on the expanded image
        isZoomed: false,
        // Scale factor: 1 means no zoom, e.g., 2 means double size
        scale: 1,
        // Transform origin (used for panning): default centered
        origin: { x: '50%', y: '50%' },
      }
    },
    computed: {
      // Computed inline styles for the zoomable image in the overlay.
      imageStyle() {
        return {
          transition: 'transform 0.3s ease',
          transform: `scale(${this.scale})`,
          transformOrigin: `${this.origin.x} ${this.origin.y}`,
          cursor: this.isZoomed ? 'move' : 'zoom-in',
        }
      }
    },
    methods: {
        // Toggle zoom in/out when the zoom button is clicked.
        toggleZoom() {
            if (this.isZoomed) {
            // Zoom out: reset to normal scale.
            this.scale = 1;
            this.origin = { x: '50%', y: '50%' };
            this.isZoomed = false;
            } else {
            // Zoom in: for example, set scale to 2.
            this.scale = 2;
            this.isZoomed = true;
            }
        },
        // As the mouse moves over the expanded overlay, update transform origin
        // so that the zoomed image pans according to the mouse position.
        onMouseMove(e) {
            if (!this.isZoomed) return;
            // Get the bounding rectangle of the image container.
            const containerRect = this.$el.querySelector('.image-container').getBoundingClientRect();
            // Calculate relative mouse position in percentage values.
            const xPercent = ((e.clientX - containerRect.left) / containerRect.width) * 100;
            const yPercent = ((e.clientY - containerRect.top) / containerRect.height) * 100;
            // Update the transform origin to pan the image.
            this.origin = { x: `${xPercent}%`, y: `${yPercent}%` };
        },
        // Touch-based panning: update transform origin based on touch position
        onTouchMove(e) {
            if (!this.isZoomed) return;
            // Prevent default behavior (like scrolling)
            e.preventDefault();
            const touch = e.touches[0];
            const containerRect = this.$el.querySelector('.image-container').getBoundingClientRect();
            const xPercent = ((touch.clientX - containerRect.left) / containerRect.width) * 100;
            const yPercent = ((touch.clientY - containerRect.top) / containerRect.height) * 100;
            this.origin = { x: `${xPercent}%`, y: `${yPercent}%` };
        },
        // Close the expanded overlay and reset zoom states.
        closeExpanded() {
            this.expanded = false;
            this.scale = 1;
            this.origin = { x: '50%', y: '50%' };
            this.isZoomed = false;
        },
    }
  }
</script>
  
<style scoped>
    .expandable-image {
        position: relative;
        transition: 0.25s opacity;
        --img-scale: 1.00;
    }

    /* Inline image styling */
    .inline-image {
        cursor: pointer;
        width: 100%;
        display: block;

        transform-origin: center;
        transform: scale(var(--img-scale));
        transition: transform 0.4s ease-in-out;
    }
    
    /* Full-screen expanded overlay with dark backdrop */
    .expanded-overlay {
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.85);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    /* Container for the zoomable image; overflow hidden ensures only the container area is visible */
    .image-container {
        position: relative;
        width: 80%;   /* adjust as needed */
        max-height: 95%;
        max-width: 1200px;
        overflow: hidden;
        display: flex;
    }
    
    /* Zoom button styling */
    .zoom-button {
        position: absolute;
        top: 10px;
        right: 60px; /* leave space for the close button */
        background: rgba(0, 0, 0, 0.5);
        border: none;
        color: #fff;
        padding: 5px 10px;
        cursor: pointer;
        z-index: 2;
        border-radius: 3px;
    }
    
    /* Close button styling */
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        color: #fff;
        padding: 5px 10px;
        cursor: pointer;
        z-index: 2;
        border-radius: 3px;
    }
    
    /* The zoomable image: take full width of container */
    .image-container img {
        display: block;
        width: 100%;
        align-self: center;
    }

    svg {
        filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.5));
    }
    svg path {
        fill: #FFF;
    }

    .expand-button {
        position: absolute;
        z-index: 999;
        right: 10px;
        top: 10px;
        padding: 3px;
        opacity: 0;
        transition: 0.2s opacity;
    }
    .expandable-image:hover .expand-button {
        opacity: 1;
    }

    .expandable-image:has(:hover, :focus) {
        --img-scale: 1.1;
    }
</style>
  